import min from "lodash/min"
import React, { useState } from "react"
import useDimensions from "react-cool-dimensions"

import { Loader } from "../components/rsvp/form/loader"
import { Menu } from "../components/menu"
import { RegistryBackground } from "../components/registry/background"
import { useInitialLoad } from "../hooks/use-initial-load"

import "./index.css"
import SEO from "../components/seo"

const ratio = 9 / 16

const Page = (props) => {
  const { location } = props

  const { ref, width } = useDimensions()
  const initialLoad = useInitialLoad()
  const [videoLoaded, setVideoLoaded] = useState(false)

  return (
    <>
      <div className="container overlay"/>
      <div className="animate-blur container" style={{ filter: "blur(0px)" }}>
        <RegistryBackground
          className="animate-opacity full-page-background"
          style={{ opacity: initialLoad ? 1 : 0 }}
        />
      </div>
      <Menu location={location}/>
      <div
        className="animate-opacity the-big-day-hero text"
        style={{ opacity: initialLoad ? 1 : 0, overflowY: "scroll" }}
      >
        <div style={{ marginBottom: "8px" }}>#WeAreAbel</div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "64px" }}>
          <div style={{ marginBottom: "48px", maxWidth: "500px", textAlign: "center" }}>
            <div>St. Peter's Roman Catholic Church<br/>June 27, 2020</div>
          </div>
          <div style={{ fontSize: "24px", marginBottom: "8px", maxWidth: "500px", textAlign: "center" }}>
            To everyone involved, for your prayers, and generous gifts.
          </div>
          <div style={{ fontSize: "18px", maxWidth: "500px", textAlign: "center" }}>Thank you.</div>
        </div>

        {!videoLoaded && (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "64px" }}>
            <div style={{ width: "500px"}}>
              <Loader />
            </div>
          </div>
        )}

        <div ref={ref} style={{ overflowX: "scroll" }}>
          <iframe
            className="animate-opacity"
            onLoad={() => setVideoLoaded(true)}
            src="https://player.vimeo.com/video/478160031"
            height={min([ratio * width, 607.5])}
            width="100%"
            style={{
              border: "none",
              opacity: initialLoad && videoLoaded ? 1 : 0,
              overflow: "hidden",
              maxWidth: "1080px",
            }}
            scrolling="no"
            frameBorder="0"
            allowTransparency="true"
            allowFullScreen={true}
            title="We Are Abel | Highlights"
          />
        </div>
      </div>
    </>
  )
}

const IndexPage = (props) => (
  <>
    <SEO title="Highlights"/>
    <Page location={props.location}/>
  </>
)

export default IndexPage
